
type StyledButtonProps = {
  text: string
  icon: string
  border?: string
  bgColor?: string
  color?: string
  fontWeight?: number
  borderRadius?: string
  padding?: string
  handleClick?: () => void
}

const StyledButton = ({ 
  text, 
  icon, 
  border="1px solid silver",
  bgColor,
  color,
  fontWeight,
  borderRadius="5px",
  padding="8px",
  handleClick
}: StyledButtonProps ) => {
  return (
    <div 
      className='styled-button d-flex justify-content-center align-items-center gap-2'
      style={{
        border,
        background: bgColor,
        borderRadius,
        padding
      }}
      onClick={handleClick}
    >
      <img src={icon} alt="" />
      <p className='mb-0' style={{ color, fontWeight }}>{text}</p>
    </div>
  )
}

export default StyledButton