import { GoogleMap, useJsApiLoader, InfoWindow } from '@react-google-maps/api';
import MapCard from '../MapCard';
import { useCallback, useEffect, useRef, useState } from 'react';
import { LocationsListing } from 'src/pages/Home/components/PropertyCollection';
import useSupercluster from 'use-supercluster';
import ClusterMarker from '../ClusterMarker';
import { LocationType } from 'src/utils/tour-now';
import LocationMarker from '../LocationMarker';

type GMapProps = {
  locations: LocationsListing[];
  selectedLocation: LocationsListing | null;
  setSelectedLocation: (args: LocationsListing) => void;
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

const themeOptions = {
  styles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          hue: '#ff4400',
        },
        {
          saturation: -100,
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          hue: '#ff4400',
        },
        {
          saturation: -100,
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#8AB4F8', // Set the desired ocean color (blue)
        },
      ],
    },
  ],
};

const GMap = ({
  locations,
  selectedLocation,
  setSelectedLocation,
}: GMapProps) => {
  const mapKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

  const mapRef: any = useRef();
  const [bounds, setBounds] = useState<any>(null);
  const [mapCenter, setMapCenter] = useState<LocationType>({
    lat: 46.773726436773224,
    lng: -100.93661663130534,
  });
  const [zoom, setZoom] = useState(10);
  const [initialRender, setInitialRender] = useState(true);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: mapKey || '',
  });

  useEffect(() => {
    setInitialRender(false);
    if (locations.length > 0 && !initialRender) {
      setMapCenter({
        lat: locations[0]?.latitude,
        lng: locations[0]?.longitude,
      });
    }
  }, [locations, initialRender]);

  useEffect(() => {
    if (mapRef.current && locations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach((location) => {
        bounds.extend(new window.google.maps.LatLng(location.latitude, location.longitude));
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [locations]);

  const onSelectLocation = (location: any) => {
    setSelectedLocation(location);
  };

  const onLoad = useCallback(function callback(map: any) {
    mapRef.current = map;
    if (locations.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach((location) => {
        bounds.extend(new window.google.maps.LatLng(location.latitude, location.longitude));
      });
      map.fitBounds(bounds);
    }
  }, [locations]);

  const points = locations?.map((location, index) => ({
    type: 'Feature',
    properties: {
      cluster: false,
      crimeId: index,
      category: 'property',
    },
    geometry: {
      type: 'Point',
      coordinates: [location.longitude, location.latitude],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const handleMap = () => {
    const mapZoom = mapRef.current?.getZoom();
    const mapBounds = mapRef.current?.getBounds();
    if (mapZoom) {
      setZoom(mapZoom);
    }
    if (mapBounds) {
      const [west, south, east, north] = [
        mapBounds.getSouthWest().lng(),
        mapBounds.getSouthWest().lat(),
        mapBounds.getNorthEast().lng(),
        mapBounds.getNorthEast().lat(),
      ];
      setBounds([west, south, east, north]);
    }
  };

  return (
    <div style={{ height: '100%' }}>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          onLoad={onLoad}
          zoom={4}
          options={themeOptions}
          onZoomChanged={handleMap}
          onBoundsChanged={handleMap}
        >
          {clusters?.length > 0
            ? clusters.map((location, index) => {
                const { cluster: isCluster, point_count, crimeId } = location.properties;
                const [longitude, latitude] = location.geometry.coordinates;

                return (
                  <div key={index}>
                    {isCluster ? (
                      <ClusterMarker
                        position={{ lat: latitude, lng: longitude }}
                        supercluster={supercluster}
                        id={location.id}
                        mapRef={mapRef}
                        pointCount={point_count}
                      />
                    ) : (
                      <LocationMarker
                        price={locations[crimeId]?.price}
                        position={{ lat: locations[crimeId]?.latitude, lng: locations[crimeId]?.longitude }}
                        onClick={() => onSelectLocation(locations[crimeId])}
                      />
                    )}
                  </div>
                );
              })
            : locations?.map((location, index) => (
                <LocationMarker
                  key={index}
                  price={location?.price}
                  position={{ lat: location?.latitude, lng: location?.longitude }}
                  onClick={() => onSelectLocation(location)}
                />
              ))}

          {selectedLocation && (
            <InfoWindow
              position={{ lat: selectedLocation.latitude, lng: selectedLocation.longitude }}
              options={{
                pixelOffset: new window.google.maps.Size(0, -10),
              }}
              onCloseClick={() => onSelectLocation(null)}
            >
              <MapCard selectedLocation={selectedLocation} onClose={() => onSelectLocation(null)} />
            </InfoWindow>
          )}
        </GoogleMap>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default GMap;