import { configureStore } from '@reduxjs/toolkit'
import builderReducer from './builderSlice'
import fetchPropertiesSlice from './propertySlice/fetchPropertiesSlice'

export const store = configureStore({
  reducer: {
    builder: builderReducer,
    propertiesByBuilder: fetchPropertiesSlice
  },
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

