export type SuperclusterType = {
  getClusters: (bounds: [number, number, number, number], zoom: number) => any[];
  getLeaves: (clusterId: number, limit?: number, offset?: number) => any[];
  getClusterExpansionZoom: (id: number) => any;
};

export type LocationType = {
  lat: number,
  lng: number
}

export type PropertyFiltersType = {
  maxBedrooms: number,
  maxFullBathrooms: number,
  maxHalfBathrooms: number
}

export const formatPrice = (n: number) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "k";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};
