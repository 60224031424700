
import './style.scss';

import Home from './pages/Home';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function App() {

  const location = useLocation()

  useEffect(() => {
    localStorage.setItem('builder_name', location.pathname.split('/')[1]);
  }, [location])
  

  return (
    <div>
      <Routes>
        <Route path="/:builder_name" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
