import StyledButton from "../StyledButton"
import nterNowIcon from 'src/assets/images/icn-nternow.svg'
import callIcon from 'src/assets/images/icn-call.svg'
import detailIcon from 'src/assets/images/icn-detail.svg'
import bath from 'src/assets/images/icn-bath-fill.svg'
import bed from 'src/assets/images/icn-bed-fill.svg'
import roomSize from 'src/assets/images/icn-sqft-fill.svg'
// import garage from 'src/assets/images/icn-garage-fill.svg'
import TextWithIcon from "../TextWithIcon"
import { useSelector } from "react-redux"
import { RootState } from "src/state/store"
import { LocationsListing } from "src/pages/Home/components/PropertyCollection"
import { useLocation } from "react-router-dom"

type LocationCardProps = {
  location: LocationsListing
}

const LocationCard = ({ location } : LocationCardProps) => {

  const redirectUrl = process.env.REACT_APP_REDIRECT_URL

  const url = useLocation()

  const builderDetails = useSelector((state: RootState) => state.builder.settings);
  const builder_name =  url?.pathname?.split('/')[1]

  const handleClick = () => {
    window?.open(`${redirectUrl}${builder_name}/property/${location?.property_id}`, '_blank')
  }

  const formatPhoneNumber = (phoneNumber: string) => {
    let cleaned = phoneNumber.replace(/[^+\d]/g, '');

    if (cleaned.startsWith('+1') && cleaned.length === 12) {
        return cleaned; 
    }

    if (cleaned.startsWith('1') && cleaned.length === 11) {
        return `+${cleaned}`;
    }

    if (cleaned.length === 10) {
        return `+1${cleaned}`;
    }

    throw new Error('Invalid phone number length');
  }

  const handleViewDetails = () => {
    window.open(location.url_1, '_blank');
  }

  const handleTalkToSales = () => {
    window.location.href = `tel:${formatPhoneNumber(location.sales_agent as string)}`
  }


  return (
    <div className='location-card'>
      <div id='223das' className='location-img-box'>
        {location?.url_image &&
          <img src={location?.url_image} alt="card-img" className='location-img' />
          // :
          // <HomeVector />
        }
      </div>
      <div className='location-content'>
        <h4 className="mb-1">{location?.address_1}</h4>
        <p>PropertyID: {location?.property_id}</p>
        <p>{location?.subdivision}, {location?.city}, {location?.state}, {location?.zipcode}</p>
        <TextWithIcon text={location?.bedrooms + ' Beds'} icon={bed} />
        <TextWithIcon text={location?.fullBathrooms + ' Fba'} icon={bath} />
        <TextWithIcon text={location?.halfBathrooms + ' Hba'} icon={bath} />
        <TextWithIcon text={location?.squareFootage + ' Sq Ft'} icon={roomSize} />
        {/* <TextWithIcon text='2 - 3 Car garage' icon={garage} /> */}
        <h6 className="price">Starting at ${location?.price?.toLocaleString()}</h6>

        <div className='button-container pt-3 pb-2' >
          <StyledButton
            text="Self Tour" 
            icon={nterNowIcon} 
            border={`1px solid ${builderDetails?.primary_color || "#3E864A"}`}
            bgColor={builderDetails?.primary_color || "#3E864A"}
            color="white"
            handleClick={handleClick}
          />

          { location.sales_agent &&
          <StyledButton handleClick={() => handleTalkToSales()} text="Talk to sales" icon={callIcon} 
          />}

          { location.url_1 && 
            <StyledButton handleClick={() => handleViewDetails()} text="View details" icon={detailIcon} 
          />}
        </div>
      </div>
    </div>
  )
}

export default LocationCard