type TextWithIconProps = {
  icon: string;
  text: string | number;
}

const TextWithIcon = ({ text, icon }: TextWithIconProps ) => {
  return (
    <div className='text-icon d-flex align-items-center gap-2'>
      <img src={icon} alt="" />
      <span>{text}</span>
    </div>
  )
}

export default TextWithIcon;