import Accordion from 'react-bootstrap/Accordion';

type FilterBoxProps = {
  children: React.ReactNode
  title: string
}

const AccordionBox = ({ title, children }: FilterBoxProps ) => {

  return (
    <Accordion defaultActiveKey={[title]} alwaysOpen>
      <Accordion.Item eventKey={title}>
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionBox;