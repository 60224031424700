// import GoogleMapReact from 'google-map-react';
// import GoogleMap from 'google-maps-react-markers'
// import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';

import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import Heading from 'src/components/UI/Heading';
import StyledButton from 'src/components/UI/StyledButton'
import filterIcon from 'src/assets/images/icn-filter.svg'
import 'rc-slider/assets/index.css';
import AccordionBox from 'src/components/UI/Accordion';
import LocationCard from 'src/components/UI/LocationCard';
import GMap from 'src/components/UI/GMap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/state/store';
import RangeSlider from 'src/components/UI/RangeSlider';
import { fetchPropertiesByBuilder } from 'src/state/propertySlice/fetchPropertiesSlice';
import { PropertyFiltersType, formatPrice } from 'src/utils/tour-now';
import { Element } from 'react-scroll';
import { Button, Spinner } from 'react-bootstrap';
import editIcon from 'src/assets/images/edit.svg'
import { http } from 'src/utils/axios';
import { useLocation } from 'react-router-dom';

export type LocationsListing = {
  property_id: number
  address_1: string
  address_2: string
  builder: string
  bedrooms: number
  timezone: number
  fullBathrooms: number
  halfBathrooms: number
  squareFootage: number
  subdivision: string
  lock_serial_number: string
  smart_lock_serial_number: string
  disposition_id: number
  city: string
  state: string
  url_1: string
  zipcode: string
  house_num: string
  price: string
  longitude: number
  latitude: number
  url_image: string
  sales_agent?: string
}

type OptionType = { 
  value: string, label: string
 }

const options: OptionType[] = [
  { value: 'price', label: 'Sort By Price' },
  { value: 'modified_on', label: 'Sort By Date' },
];

type TabsType = {
  key: string;
  value: string;
}

const tabs: TabsType[] = [
  {
    key: 'Map',
    value: 'map'
  },
  {
    key: 'List',
    value: 'list'
  },
]

const defaultFilters = {
  price: [0, 2000000],
  beds: null,
  fullBaths: null,
  halfBaths: null,
  sqft: [0, 10000],
  sort: 'price'
}

const defaultRooms: PropertyFiltersType = {
  maxBedrooms: 0,
  maxFullBathrooms: 0,
  maxHalfBathrooms: 0
}

const PropertyCollection = () => {

  const dispatch: AppDispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [selectedLocation, setSelectedLocation] = useState<LocationsListing | null>(null);
  const [selectedOption, setSelectedOption] = useState<OptionType>(options[0]);
  const [selectedTab, setSelectedTab] = useState<TabsType>(queryParams.get('showList') ? tabs[1] : tabs[0]);
  const [showFilters, setShowFilters] = useState(true);
  const [limit, setLimit] = useState(20)
  const [limitSuggestions, setLimitSuggestions] = useState(20)

  const [selectedFilters, setSelectedFilters] = useState(defaultFilters)
  const [propertyFilters, setPropertyFilters] = useState<PropertyFiltersType>(defaultRooms)

  const [priceSlider, setPriceSlider] = useState(defaultFilters.price)

  const [areaSlider, setAreaSlider] = useState(defaultFilters.sqft)

  const [isEdit, setIsEdit] = useState('')

  const builderId = useSelector((state: RootState) => state.builder.builder_id);
  const builderDetails = useSelector((state: RootState) => state.builder.settings);
  const loadingProperties = useSelector((state: RootState) => state.propertiesByBuilder.loading);
  const properties: LocationsListing[] = useSelector((state: RootState) => state.propertiesByBuilder.properties);

  useEffect(() => {
    if (builderId) {
      const payload = {
        builder_id: builderId,
        filters: selectedFilters
      }
      dispatch(fetchPropertiesByBuilder(payload))
      getPropertyFilters(builderId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builderId])

  useEffect(() => {
    if (builderId) {
      const payload = {
        builder_id: builderId,
        filters: selectedFilters
      }
      dispatch(fetchPropertiesByBuilder(payload))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters])

  useEffect(() => {
    handleFilters('sort', selectedOption?.value)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption])

  const getPropertyFilters = async (builderId: number) => {
    try {
      const resp = await http.get(`properties/dynamic-property-info/${builderId}`)
      setPropertyFilters(resp?.data)
    } catch (error) {
      console.log(error);
    }
  }

  const handlePriceField = () => {
    handleFilters('price', priceSlider)
    setIsEdit('')
  }
  
  const handleAreaField = () => {
    handleFilters('sqft', areaSlider)
    setIsEdit('')
  }

  useEffect(() => {
    isEdit && inputRef?.current?.focus();
  }, [isEdit])
  

  const handleFilters = (name: string, value: any) => {
    switch (name) {
      case 'price':
        setSelectedFilters({...selectedFilters, price: value})
        break;
      case 'beds':
        setSelectedFilters({...selectedFilters, beds: value})
        break;
      case 'fullBaths':
        setSelectedFilters({...selectedFilters, fullBaths: value})
        break;
      case 'halfBaths':
        setSelectedFilters({...selectedFilters, halfBaths: value})
        break;
      case 'sqft':
        setSelectedFilters({...selectedFilters, sqft: value})
        break;
      case 'sort':
        setSelectedFilters({...selectedFilters, sort: value})
        break;
    
      default:
        break;
    }
  }

  const resetFilters = () => {
    setSelectedFilters({
      ...defaultFilters,
      sort: selectedFilters.sort
    })
    setPriceSlider(defaultFilters.price)
    setAreaSlider(defaultFilters.sqft)
  }

  return (
    <Element name="explore-properties">
      <div className='property-collection container'>
        <Heading title="View Properties Ready for Self-Tours Now!" />
        {/* Collection Header */}
        <div className='collection-header mb-3'>
          {showFilters && <div className='filter-header'>
            <h4>Filter</h4>
            <p 
              style={{ color: builderDetails?.primary_color || '#3E864A' }}
              onClick={resetFilters}
            >
                Reset
            </p>
          </div>}
          <div className={`map-header ${showFilters ? '' : 'expanded'}`}>
            <div className='d-flex align-items-center gap-2'>
              <div className='map-box d-flex align-items-center'>
                {tabs.map((tab, index) => 
                <div 
                  key={index}
                  className='switch-tab' 
                  onClick={() => setSelectedTab(tab)}
                  style={{ 
                    backgroundColor: selectedTab.value === tab.value ? 
                    (builderDetails?.primary_color || '#3e864a') : '',
                    color: selectedTab.value === tab.value ? 'white' : '',
                  }}
                  >{tab.key}</div>
                )}
              </div>
               {loadingProperties && builderId && 
                <Spinner
                  className='props-spinner-lg'
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ color: builderDetails?.primary_color || '#3e864a' }}
                />
                }
            </div>
            
            <div className='filter-box d-flex align-items-stretch gap-3'>
              <StyledButton
                text={showFilters ? 'Hide Filters' : 'Show Filters'} 
                icon={filterIcon}
                color='black'
                border='1px solid black'
                fontWeight={500}
                padding='8px 12px'
                handleClick={() => setShowFilters(prev => !prev) }
              />
              <Select
                isSearchable={false}
                value={selectedOption}
                className='react-select-container'
                classNamePrefix="react-select"
                onChange={opt => opt && setSelectedOption(opt)
                }
                options={options}
                styles={{
                  control: (baseStyles, state): any => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? 'black' : 'black',
                    boxShadow: state.isFocused ? 'none' : 'none'
                  }),
                }}
              />
            </div>
          </div>
        </div>
        {/* Collection Body */}
        <div className='collection-container'>
          {/* Filter Section */}
          {showFilters && <div className='filter-section'>
            <div>
              <AccordionBox title="Price">
                <div className='pb-2' style={{ 
                  width: 'calc(100% - 45px)', 
                  margin: '0 auto', 
                  transform: 'translateX(-20px)' 
                  }}>
                    <RangeSlider
                      min={selectedFilters.price[0] < 0 ? selectedFilters.price[0] : 0}
                      max={selectedFilters.price[1] > 2000000 ? selectedFilters.price[1] : 2000000}
                      value={selectedFilters.price}
                      setValue={(val) => handleFilters('price', val)}
                      rangeValue={priceSlider}
                      setRangeValue={setPriceSlider}
                    />
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  {isEdit === 'min-price' ? 
                    <input 
                      ref={inputRef}
                      type="number" 
                      value={priceSlider[0]} 
                      onChange={e => setPriceSlider(prev => [Number(e.target.value), ...prev.slice(1)])} 
                      onBlur={handlePriceField}
                      style={{ width: '100px' }}
                      min="0"
                    />
                    :
                    <div 
                      className='d-flex align-items-center gap-1' 
                      onClick={() => setIsEdit('min-price')}
                      style={{ cursor: 'pointer' }}
                    >
                      <p className='fw-medium mb-0'>${formatPrice(priceSlider[0])}</p>
                      <img src={editIcon} alt="edit-icon" className='edit-icon'  />
                    </div>
                  }
                  {isEdit === 'max-price' ?
                    <input 
                      ref={inputRef}
                      type="number" 
                      value={priceSlider[1]}
                      onChange={e => setPriceSlider(prev => [prev[0], Number(e.target.value), ...prev.slice(2)])}  
                      onBlur={handlePriceField}
                      style={{ width: '100px' }}
                      min="0"
                    />
                    :
                    <div 
                      className='d-flex align-items-center gap-1' 
                      onClick={() => setIsEdit('max-price')}
                      style={{ cursor: 'pointer' }}
                    >
                      <p className='fw-medium mb-0'>${formatPrice(priceSlider[1])}</p>
                      <img src={editIcon} alt="edit-icon" className='edit-icon'  />
                    </div>
                  }
                </div>
              </AccordionBox>
            </div>
            <div>
              <AccordionBox title="SQFT">
                <div className='pb-2' style={{ 
                  width: 'calc(100% - 45px)', 
                  margin: '0 auto', 
                  transform: 'translateX(-20px)' 
                  }}>
                    <RangeSlider
                      min={selectedFilters.sqft[0] < 0 ? selectedFilters.sqft[0] : 0}
                      max={selectedFilters.sqft[1] > 10000 ? selectedFilters.sqft[1] : 10000}
                      value={selectedFilters.sqft}
                      setValue={(val) => handleFilters('sqft', val)}
                      rangeValue={areaSlider}
                      setRangeValue={setAreaSlider}
                    />
                </div>
                <div className='d-flex justify-content-between mt-2'>
                  {isEdit === 'min-sqft' ?
                    <input 
                      ref={inputRef}
                      type="number" 
                      value={areaSlider[0]} 
                      onChange={e => setAreaSlider(prev => [Number(e.target.value), ...prev.slice(1)])} 
                      onBlur={handleAreaField}
                      style={{ width: '100px' }}
                      min="0"
                    />
                    :
                    <div 
                      className='d-flex align-items-center gap-1' 
                      onClick={() => setIsEdit('min-sqft')}
                      style={{ cursor: 'pointer' }}
                    >
                      <p className='fw-medium mb-0'>{formatPrice(areaSlider[0])} SQFT</p>
                      <img src={editIcon} alt="edit-icon" className='edit-icon'  />
                    </div>
                  }
                  {isEdit === 'max-sqft' ?
                    <input 
                      ref={inputRef}
                      type="number" 
                      value={areaSlider[1]} 
                      onChange={e => setAreaSlider(prev => [prev[0], Number(e.target.value), ...prev.slice(2)])}  
                      onBlur={handleAreaField}
                      style={{ width: '100px' }}
                      min="0"
                    />
                    :
                    <div 
                      className='d-flex align-items-center gap-1' 
                      onClick={() => setIsEdit('max-sqft')}
                      style={{ cursor: 'pointer' }}
                    >
                      <p className='fw-medium mb-0'>{formatPrice(areaSlider[1])} SQFT</p>
                      <img src={editIcon} alt="edit-icon" className='edit-icon'  />
                    </div>
                  }
                </div>
              </AccordionBox>
            </div>
            <div>
              <AccordionBox title="Beds">
                <div className='filter-tabs'>
                <div 
                    className={`tabs`} 
                    onClick={() => handleFilters('beds', null)}
                    style={{
                      backgroundColor: selectedFilters.beds === null ? 
                      (builderDetails?.primary_color || '#3E864A') : '',
                      color: selectedFilters.beds === null ? 'white' : '',
                    }}
                    >Any</div>
                  {propertyFilters?.maxBedrooms > 0 && [...Array(propertyFilters?.maxBedrooms)].map((_, index) => 
                    <div 
                      key={index}
                      className={`tabs`} 
                      onClick={() => handleFilters('beds', index + 1)}
                      style={{
                        backgroundColor: selectedFilters.beds === index + 1 ? 
                        (builderDetails?.primary_color || '#3E864A') : '',
                        color: selectedFilters.beds === index + 1 ? 'white' : '',
                      }}
                      >{index + 1}</div>
                  )}
                </div>
              </AccordionBox>
            </div>
            <div>
              <AccordionBox title="Full Baths">
                <div className='filter-tabs'>
                  <div 
                    className={`tabs`} 
                    onClick={() => handleFilters('fullBaths', null)}
                    style={{
                      backgroundColor: selectedFilters.fullBaths === null ? 
                      (builderDetails?.primary_color || '#3E864A') : '',
                      color: selectedFilters.fullBaths === null ? 'white' : '',
                    }}
                    >Any</div>
                {propertyFilters?.maxFullBathrooms > 0 && [...Array(propertyFilters?.maxFullBathrooms)].map((_, index) => 
                    <div 
                      key={index}
                      className={`tabs`} 
                      onClick={() => handleFilters('fullBaths', index + 1)}
                      style={{
                        backgroundColor: selectedFilters.fullBaths === index + 1 ? 
                        (builderDetails?.primary_color || '#3E864A') : '',
                        color: selectedFilters.fullBaths === index + 1 ? 'white' : '',
                      }}
                      >{index + 1}</div>
                  )}
                </div>
              </AccordionBox>
            </div>
            <div>
              <AccordionBox title="Half Baths">
                <div className='filter-tabs'>
                  <div 
                    className={`tabs`} 
                    onClick={() => handleFilters('halfBaths', null)}
                    style={{
                      backgroundColor: selectedFilters.halfBaths === null ? 
                      (builderDetails?.primary_color || '#3E864A') : '',
                      color: selectedFilters.halfBaths === null ? 'white' : '',
                    }}
                    >Any</div>
                  {propertyFilters?.maxHalfBathrooms > 0 && [...Array(propertyFilters?.maxHalfBathrooms)].map((_, index) => 
                    <div 
                      key={index}
                      className={`tabs`} 
                      onClick={() => handleFilters('halfBaths', index + 1)}
                      style={{
                        backgroundColor: selectedFilters.halfBaths === index + 1 ? 
                        (builderDetails?.primary_color || '#3E864A') : '',
                        color: selectedFilters.halfBaths === index + 1 ? 'white' : '',
                      }}
                      >{index + 1}</div>
                  )}
                </div>
              </AccordionBox>
            </div>
          </div>}
          {/* Map Section */}
          <div className={`map-section ${showFilters ? '' : 'expanded'}`}>
            {/* For Mobile Screen */}
            <div className='map-box align-items-center mb-3'>
              {tabs.map((tab, index) => 
              <div 
                key={index}
                className='switch-tab position-relative' 
                onClick={() => setSelectedTab(tab)}
                style={{ 
                  backgroundColor: selectedTab.value === tab.value ? 
                  (builderDetails?.primary_color || '#3E864A') : '',
                  color: selectedTab.value === tab.value ? 'white' : '',
                }}
                >
                  {tab.key}
                  {(selectedTab.value === tab.value) && 
                    loadingProperties && builderId && 
                    <Spinner
                      className='props-spinner'
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  }
                </div>
              )}
            </div>
            {properties?.length === 0 ? 
              <p className='text-center'>No properties found</p> 
              :
              selectedTab.value === 'map' ? 
                <div className='google-maps-container'>
                  <GMap
                    locations={properties}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                  /> 
                </div>
                :
                <div className="listing-wrapper">
                  <div className={`listing-container ${showFilters ? '' : 'expanded'}`}>
                    {properties.slice(0, limit).map((location, index) =>
                      <LocationCard key={index} location={location}/>
                    )}
                  </div>

                  {properties?.length > limit && 
                    <Button 
                    onClick={() => setLimit(prev => prev + 20)} 
                    className='d-block m-auto mt-4 px-4'
                    style={{ border: 'none', background: builderDetails?.primary_color }}>
                      Load More
                    </Button>
                  }
                </div>
            }  
          </div>
          {/* Listings */}
          {selectedTab.value === 'map' && <div className='places-section'>
          {
            properties?.length > 0 ? properties.slice(0, limitSuggestions).map((location, index) => <LocationCard key={index} location={location}/>) 
            :
            <p className='text-center'>No properties found</p> 
          }
          {properties?.length > limitSuggestions && 
            <Button 
            onClick={() => setLimitSuggestions(prev => prev + 20)} 
            className='d-block m-auto mt-4 px-4'
            style={{ border: 'none', background: builderDetails?.primary_color }}>
              Load More
            </Button>
          }
          </div>}
        </div>
      </div>
    </Element>
  )
}

export default React.memo(PropertyCollection)