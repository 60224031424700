import Heading from "src/components/UI/Heading";
import { useSelector } from "react-redux";
import { RootState } from "src/state/store";
import { Icon } from '@iconify/react';
import { Element } from "react-scroll";


const infoList = [
  {
    title: 'Find a home',
    detail: 'Search our completed homes  that offer self-guided tours​.',
    icon: "mingcute:search-fill"
  },
  {
    title: 'Verify Identity',
    detail: 'Quickly create an account with a valid ID to pass verification. Takes less than 1 minute.',
    icon: "fluent:notepad-person-20-filled"
  },
  {
    title: 'Get the access',
    detail: 'We will send you directions to the home & access code to unlock the home! Tour anytime!',
    icon: "mingcute:open-door-fill"
  },
  {
    title: 'Visit Anytime',
    detail: 'Enjoy the liberty to explore at your own pace. Tour as many homes as you can!',
    icon: "solar:calendar-bold"
  },
]

const WhatYouGet = () => {

  const builderDetails = useSelector((state: RootState) => state.builder.settings);

  return (
    <Element name="how-it-works">
      <div className='what-you-get container'>
        <Heading title="How NterNow Works!" />

        <div className='card-container'>
        {infoList.map((info, index) => <div key={index} className='card'>
          <div className='card-icon' style={{ backgroundColor: builderDetails?.primary_color ? `${builderDetails?.primary_color}10` : '#3E864A10'}}>
            <Icon icon={info.icon} className="icon" style={{ color: builderDetails?.primary_color || '#3E864A' }} />
          </div>
          <h4 className="mt-2">{info.title}</h4>
          <p>{info.detail}</p>
        </div>)}

        </div>
      </div>
    </Element>
  )
}

export default WhatYouGet;