import { Icon } from '@iconify/react'
import { OverlayView, OverlayViewF } from '@react-google-maps/api'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/state/store'
import { LocationType } from 'src/utils/tour-now'

type LocationMarkerType = {
  price: number | string,
  position: LocationType,
  onClick: () => any,
}

const LocationMarker = ({
  price,
  position,
  onClick,
}: LocationMarkerType) => {

  const builderDetails = useSelector((state: RootState) => state.builder.settings);

  return (
    <OverlayViewF
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div 
        className='location-marker d-flex align-items-center gap-2 p-2' 
        style={{ backgroundColor: builderDetails?.primary_color || '#3E864A'  }} 
        onClick={onClick}
      >
        <Icon icon="ion:home" className='home-icon' />
        <p className='m-0 fw-medium '>
          ${price?.toLocaleString()}
        </p>
      </div>
    </OverlayViewF>
  )
}

export default LocationMarker