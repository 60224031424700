// import Header from 'src/components/common/Header';
import PropertyCollection from './components/PropertyCollection';
import Poster from './components/Poster';
import WhatYouGet from './components/WhatYouGet';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchBuilderDetails, getBuilderId } from 'src/state/builderSlice';
import { AppDispatch, RootState } from 'src/state/store';
import { useLocation } from 'react-router-dom';
import Spinner from 'src/components/common/Spinner';

const Home = () => {
  const dispatch: AppDispatch = useDispatch()
  const isLoading = useSelector((state: RootState) => state.builder.loading);
  const builderId = useSelector((state: RootState) => state.builder.builder_id);
  
  const location = useLocation()
  
  useEffect(() => {
    const builder_name =  location.pathname.split('/')[1]
    if(builder_name) {
      dispatch(getBuilderId(builder_name))
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(builderId) {
      dispatch(fetchBuilderDetails(builderId))
    } 
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builderId])
  
  

  return (
    <div className='tour-now-container'>
      {isLoading && <Spinner />}
      {/* <Header /> */}
      <Poster />
      <WhatYouGet />
      <PropertyCollection />
    </div>
  )
}

export default Home