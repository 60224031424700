import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'src/state/store';
import { SuperclusterType } from 'src/utils/tour-now';

type ClusterMarkerProps = {
  position: LocationType;
  supercluster: SuperclusterType;
  id: number;
  mapRef: any;
  pointCount: number;
}

type LocationType = {
  lat: number,
  lng: number
}

const ClusterMarker = ({
  position, 
  supercluster, 
  id, 
  mapRef, 
  pointCount
}: ClusterMarkerProps) => {

  const builderDetails = useSelector((state: RootState) => state.builder.settings);

  return (
    <OverlayViewF
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div 
        className='location-marker d-flex align-items-center gap-2 p-2' 
        style={{ backgroundColor: builderDetails?.primary_color || '#3E864A'  }} 
        onClick={() => {
          const expansionZoom = Math.min(
            supercluster.getClusterExpansionZoom(id),
            30
          );
          mapRef?.current?.setZoom(expansionZoom);
          mapRef?.current?.panTo(position);
        }}
      >
        <p className='m-0 fw-medium'>
          {pointCount} NEW HOMES
        </p>
      </div>
    </OverlayViewF>
  )
}

export default React.memo(ClusterMarker)