import Slider from 'rc-slider';
import { useSelector } from 'react-redux';
import { RootState } from 'src/state/store';

type RangeSliderProps = {
  min?: number
  max?: number
  value: number[] 
  setValue: (args: number[]) => void
  rangeValue: number[] 
  setRangeValue: (args: number[]) => void
}

const RangeSlider = ({ 
  setValue, 
  rangeValue, 
  setRangeValue, 
  min = 0, 
  max = 100 }: RangeSliderProps) => {

  const builderDetails = useSelector((state: RootState) => state.builder.settings);

  return (
    <div>
      <Slider
        range
        allowCross={false}
        min={min}
        max={max}
        step={5}
        value={rangeValue}
        onChange={(val: any) => setRangeValue(val)}
        onChangeComplete={(val: any) => setValue(val)}
        styles={{
          handle: {
            border: `10px solid ${builderDetails?.primary_color || '#3E864A'}`,
            background: 'white',
            outline: 'none',
            height: '30px',
            width: '30px',
            margin: '0',
            top: '50%',
            transform: 'translateY(-50%)',
            opacity: 1
          },
          tracks: {
            background: builderDetails?.primary_color || `#3E864A`,
            borderRadius: 0,
            height: '6px',
            transform: 'translateX(1%)',
          },
          track: {
            background: 'transparent',
          },
          rail: {
            left: '3.5%',
            height: '6px'
          }
        }}
      />
    </div>
  )
}

export default RangeSlider